import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../../components";

const FacebookAds = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Facebook Ads",
        href: pathname,
        lang: "en"
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/facebook-ads/",
      }}
    >
      <section className="facebook_section">
        <div className="text-center">
          <h1>Facebook Ads</h1>
        </div>

        <div className="row facebook_row">
          <div className="col-md-9 m-auto">
            <p>
              With the help of Facebook Ads campaigns, we can achieve various marketing goals - increase brand recognition, direct users to a specific website, acquire leads, or promote posts from a fanpage. It is also an additional tool for e-commerce - the Facebook Ads campaign is a great support for marketing activities for online stores. Ads can be displayed not only on Facebook, but also on Instagram or in instant materials. Facebook itself provides many advertising spaces, including: the main feed, the right column, in the videos being played, in the Messenger application, or in the stories.
            </p>
          </div>
        </div>
        <div className="row facebook_row facebook-img">
          <img
            src={require("../../../assets/img/offer/fb-ads.jpg")}
            className="img-fluid m-auto"
            alt=""
          />
        </div>
        <div className="row">
          <div className="col-md-10 m-auto">
            <p>
              Thanks to Facebook Ads, we reach groups of users with various interests, e.g. fans of a specific car brand, or on the basis of precise demographic and geographic data - e.g. women aged 20-35, living within 20 km of a given city, having children in the age of 0-3 years. In addition, Facebook Ads allows you to display ads to users who have visited the website, interacted with the ad, or opened the form but did not complete it.
            </p>
            <p>   
              The possibilities of promoting your business on Facebook are huge, and our specialists are the greatest enthusiasts of novelties in the field of advertising methods, which they willingly use in their every day work.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Other services</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Web Analytics</span>
            <Link to="/en/offer/web-analytics/">See</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/en/offer/social-media/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/en/offer/linkedin-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Banner ads</span>
            <Link to="/en/offer/banner-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google Ads</span>
            <Link to="/en/offer/google-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>International Marketing Campaigns</span>
            <Link to="/en/offer/international-campaigns/">see</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default FacebookAds;
